"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");

function _interopDefault(e) {
  return e && "object" == _typeof(e) && "default" in e ? e.default : e;
}

Object.defineProperty(exports, "__esModule", {
  value: !0
});

var React = _interopDefault(require("react"));

function AppContainer(e) {
  return AppContainer.warnAboutHMRDisabled && (AppContainer.warnAboutHMRDisabled = !0, console.error("React-Hot-Loader: misconfiguration detected, using production version in non-production environment."), console.error("React-Hot-Loader: Hot Module Replacement is not enabled.")), React.Children.only(e.children);
}

AppContainer.warnAboutHMRDisabled = !1;

var hot = function e() {
  return e.shouldWrapWithAppContainer ? function (e) {
    return function (n) {
      return React.createElement(AppContainer, null, React.createElement(e, n));
    };
  } : function (e) {
    return e;
  };
};

hot.shouldWrapWithAppContainer = !1;

var areComponentsEqual = function areComponentsEqual(e, n) {
  return e === n;
},
    setConfig = function setConfig() {},
    cold = function cold(e) {
  return e;
},
    configureComponent = function configureComponent() {};

exports.AppContainer = AppContainer, exports.hot = hot, exports.areComponentsEqual = areComponentsEqual, exports.setConfig = setConfig, exports.cold = cold, exports.configureComponent = configureComponent;